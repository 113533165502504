import axios from 'axios';


const URL = 'https://carene.prod-veos.iga.fr/rs/rsExtranet2'

let apiToken = null;

export function setApiToken(t)  {
    apiToken = t;
}

export async function login(username, password) {
    const r = await axios.post(URL + '/login', {
        "uid": username,
        "passwd": password
    })
    r.data && r.data.token && setApiToken(r.data.token);
    return r.data;
}

export async function boby(query, params) {
    const r = await axios.get( URL+'/boBy/'+query, {
        params: params,
        headers: {
            "Authorization": "Bearer "+apiToken
        }
    });

    return r.data;
}

