import * as React from 'react';
import Box from "@mui/material/Box";
import CompteClient from "../CompteClient/CompteClient";


export default function ClientPage() {

    //const [, setValue] = React.useState(0);

    /*const handleChange = (event, newValue) => {
        setValue(newValue);
    }*/

    return <Box sx={{width:'100%'}}>
        <CompteClient/>

    </Box>
}

/*
        <Tabs value={value} onChange={handleChange}>
            <Tab label={"Compte client"} value="0" />
        </Tabs>
        {
            value == 0 && <CompteClient/>
        }

 */