import {
    CardContent,
    Paper,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useState} from "react";
import {login} from "../../services/VeosApiService";


export default function Login({ setToken }) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = function () {
        login(username, password).then( (response) => {
            setToken(response.token);
        });
    }

    return <div style={{display:"flex",
                        height:"100vh",
                        width:"100vw",
                        alignItems:"center",
                        justifyContent:"center",
                        backgroundColor:"#F0F0F0"}}>

        <Paper elevation={6}>
            <CardContent>
                <Stack alignItems="stretch" direction={"column"} spacing={2} sx={{width:'350px'}}>
                    <Typography variant={"h5"}>Se connecter</Typography>
                    <TextField name="login" label={"Nom utilisateur"} onChange={(e) => setUsername(e.target.value)}></TextField>
                    <TextField name="password" label={"Mot de passe"} type={"password"} onChange={(e) => setPassword(e.target.value)}></TextField>
                    <Button variant="contained" onClick={() => handleLogin()} size={"large"}>Se connecter</Button>
                </Stack>
            </CardContent>
        </Paper>

    </div>

}