
import * as React from 'react';
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {boby} from "../../services/VeosApiService";
import {FormControlLabel, Switch} from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import IconButton from "@mui/material/IconButton";
import {FileDownload} from "@mui/icons-material";
import writeXlsxFile from "write-excel-file";

export default function CompteClient() {

    const [rawRows, setRawRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [dtStart, setDtStart] = useState(dayjs().startOf('year'));
    const [dtEnd, setDtEnd] = useState(dayjs().endOf('year'));
    const [showPayed, setShowPayed] = useState(true);
    const [solde, setSolde] = useState((0));
    const [soldePrev, setSoldePrev] = useState((0));
    const {personId} = useParams();

    function structPol(p) {
        return {
            solde : 0,
            soldePrev: 0,
            ops: [],
            opsPer: [],
            totCredit: 0,
            totDebit: 0,
            code: p
        };
    }

    useEffect(() => {

        boby('WS_CARAPP_ACCOUNT_SOLDE', {
            'ID_PER': personId,
        }).then((soldeResult) => {
            // Initialisation structure et calcul des soldes actuels :
            const data = { solde:0, soldePrev: 0, polices:[], m:{},
                totCredit: 0, totDebit: 0, ops:[], opsPer:[] };

            // Calcul des soldes actuels
            soldeResult.beans.forEach((cur)=>{
                const value = (cur.S === 'D' ? -1:1) * cur.M;
                const k = cur.P || '';
                if(!data.m.hasOwnProperty(k)) {
                    const pol = structPol(k);
                    data.polices.push(pol);
                    data.m[k] = pol;
                }
                data.m[k].solde+=value;
                data.solde += value;
            });
            console.log(data);

            const excludeStatus = ['CQI05', 'CQI03', 'CQI02','CQI06'];

            // Requête des opérations :
            boby('WS_CARAPP_ACCOUNT_COMPTE', {
                'ID_PER': personId,
                'DT_DEB': dtStart.format('YYYY-MM-DD'),
                'DT_FIN': dayjs().format('YYYY-MM-DD')
            }).then( results => {

                const operations = results.beans.map( r => ({
                    id: r.ID_OPER,
                    NUM_POL : r.NUM_POL,
                    LIBELLE: r.LIB_OPER,
                    LIB_SQ: r.CD_SQ ? r.LIB_SQ+' ('+r.CD_SQ+')' : '',
                    CD_SQ: r.CD_SQ,
                    TYPE: r.CD_OP, // === 'EMQUI' ? r.CD_NATQ : r.CD_OP,
                    OP: '',
                    DT_OP: r.DT,
                    DT_EFFET: r.DATDEB_QUI,
                    C: r.SENS === 'C' ? r.MNT * 1 : 0,
                    D: r.SENS === 'D' ? r.MNT * 1 : 0,
                    CD_NATQ  : r.CD_NATQ || 'EMQUI'
                }));

                // On ignore les ligne "Com Réglées"
                // operations = operations.filter( o => o.CD_SQ !== 'CQI03');

                const dtStartStr = dtStart.format('YYYY-MM-DD');
                const dtEndStr = dtEnd.format('YYYY-MM-DD');

                // Répartition dans la structure
                operations.forEach( o => {
                    const k = o.NUM_POL || '';
                    if(!data.m.hasOwnProperty(k)) {
                        const pol = structPol(k);
                        data.polices.push(pol);
                        data.m[k] = pol;
                    }
                    const p = data.m[k];
                    p.ops.push(o);
                    if(o.DT_OP <= dtEndStr) p.opsPer.push(o);
                    if(excludeStatus.indexOf(o.CD_SQ)===-1) {
                        if(excludeStatus.indexOf(o.CD_SQ)===-1) {
                            p.totDebit += o.D;
                            p.totCredit += o.C;
                        }
                        data.totDebit += o.D;
                        data.totCredit += o.C;
                    }
                });

                console.log(data);

                data.soldePrev =Math.round(100* (data.solde - data.totCredit + data.totDebit))/100;
                data.polices.forEach( p => {
                    if(p.code === '') {
                        p.soldePrev = data.soldePrev;
                    } else {
                        p.soldePrev = p.solde - p.totCredit + p.totDebit;
                    }
                });

                // Construction du tableau :
                const r = [];
                let totCredit = 0, totDebit = 0;

                data.polices.forEach( p => {
                    if(p.soldePrev !== 0) {
                        r.push({
                            id: p.code+'#prev',
                            row: 'S',
                            NUM_POL: p.code,
                            LIBELLE: 'Solde précédent',
                            OP: 'Solde précédent',
                            TYPE: 'SOLDE',
                            DT: dtStartStr,
                            C: p.soldePrev > 0 ? p.soldePrev:0,
                            D: p.soldePrev<0 ? -p.soldePrev:0
                        });
                    }
                   let totPolCredit = 0, totPolDebit = 0;
                   p.opsPer.forEach( o => {
                       if(excludeStatus.indexOf(o.CD_SQ)===-1) {
                           totPolCredit += o.C;
                           totPolDebit += o.D;
                           totCredit += o.C;
                           totDebit += o.D;
                       }
                       r.push({
                           row:(o.CD_SQ==='AQI00' || o.CD_SQ==='A01') && o.TYPE==='EMQUI'?'I':'O',
                           ...o,
                           NUM_POL: p.code,
                           TYPE: o.TYPE === 'EMQUI' ? o.CD_NATQ : o.TYPE
                       });
                   });
                   r.push({
                       id: p.code+'#total',
                       row: 'POL_T',
                       LIBELLE : p.code !== '' ? 'Total police' : 'Total réglement',
                       LIB_SQ: '',
                       CD_SQ: '',
                       NUM_POL: p.code,
                       TYPE: '',
                       OP: p.code !== '' ? 'Total police' : 'Total réglement',
                       DT_OP: '',
                       C: Math.round(100*totPolCredit)/100,
                       D: Math.round(100*totPolDebit)/100
                   });
                });
                r.push({
                    id: 'T',
                    row: 'T',
                    LIBELLE : 'Total',
                    LIB_SQ: '',
                    CD_SQ: '',
                    NUM_POL: '',
                    TYPE: '',
                    OP: 'Total',
                    DT_OP: '',
                    C: Math.round(100*totCredit)/100,
                    D: Math.round(100*totDebit)/100,
                });

                // Calcul d'un total débit, total crédit
                setSoldePrev(data.soldePrev);
                setSolde(Math.round(100*(totCredit - totDebit + data.soldePrev))/100);
                setRawRows(r);
            });

        });

    }, [personId, dtStart, dtEnd]);

    useEffect(() => {
        setRows( showPayed ? rawRows : rawRows.filter( r => r.row !== 'O' || r.TYPE !== 'EMQUI'));
    }, [showPayed,rawRows])


    const columns = [
        { field: 'NUM_POL', 'headerName': 'Police', width: 150},
        { field: 'LIBELLE', 'headerName': 'Opération', width: 300},
        { field: 'DT_EFFET', 'headerName': 'Date effet', renderCell: (param) => {
                if(!param.value) return '';
                return (new Date(param.value)).toLocaleDateString()
            }},
        { field: 'DT_OP', 'headerName': 'Date Op.', renderCell: (param) => {
                if(param.row.row !== 'O' || !param.value) return '';
                return (new Date(param.value)).toLocaleDateString()
            }},
        { field: 'TYPE', 'headerName': 'Type'},
        { field: 'D', 'headerName': 'Débit (€)', type: 'number', width: 160},
        { field: 'C', 'headerName': 'Crédit (€)', type: 'number', width: 160},
        { field: 'LIB_SQ', 'headerName': 'Statut', width: 300},
    ]

    const exportFile = async () => {

        const schema = [
            {
                column: "Police",
                type: String,
                value: r => r.NUM_POL,
                width: 20,
            },
            {
                column: "Opération",
                type: String,
                value: r => r.LIBELLE,
                width: 30,
            },
            {
                column: "Dt Effet",
                type: Date,
                format: 'dd/mm/yyyy',
                value: r => r.DT_EFFET ? (new Date(r.DT_EFFET)) : null
            },
            {
                column: "Dt Op",
                type: Date,
                format: 'dd/mm/yyyy',
                value: r => r.DT_OP ? (new Date(r.DT_OP)) : null
            },
            {
                column: "Type Op",
                type: String,
                value: r => r.TYPE
            },
            {
                column: "Débit (€)",
                type: Number,
                format: '#,##0.00',
                value: r => r.D,
                align: 'right'
            },
            {
                column: "Crédit (€)",
                type: Number,
                format: '#,##0.00',
                value: r => r.C,
                align: 'right'
            },
            {
                column: "Statut",
                type: String,
                value: r => r.LIB_SQ
            }
        ];
        await writeXlsxFile(rows, {
            fileName: 'Export.xlsx',
            sheet: "CompteClient",
            dateFormat: 'dd/mm/yyyy',
            stickyRowsCount: 1,
            headerStyle: {
                backgroundColor: '#eeeeee',
                fontWeight: 'bold',
                align: 'center',
                borderStyle: 'thick'
            },
            schema
        })
    }


    return <Box sx={{"m": 1,
        "& .row-cls--S": {
            backgroundColor: '#FFFFFF'
        },
        "& .row-cls--I": {
            fontWeight: 'bold',
            backgroundColor: '#FFDDDD'
        },
        "& .row-cls--T": {
            fontWeight: 'bold',
            backgroundColor: '#DDD'
        },
        "& .row-cls--POL_T": {
            fontWeight: 'bold',
            backgroundColor: '#F0F0F0'
        },
        "& .row-cls--POL_O": {
        }
    }}>
        <Box sx={{display:"flex",gap:'5px', alignItems:"center", padding:'5px'}}>
            <DatePicker
                label="Date début"
                value={dtStart}
                onChange={(value) => {
                    setDtStart(value);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
            <DatePicker
                label="Date fin"
                value={dtEnd}
                onChange={(value) => {
                    setDtEnd(value);
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
            <FormControlLabel
                label={"Afficher quittances réglées"}
                control={
                    <Switch
                        checked={showPayed}
                        onChange={(e)=> setShowPayed(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
            />
            <Box sx={{"flex":1}}></Box>
            <Box>Solde avant : <b>{soldePrev}</b></Box>
            <Box sx={{"width":'10px'}}></Box>
            <Box>Solde après : <b>{solde}</b></Box>
            <IconButton onClick={() => exportFile().catch()}><FileDownload/></IconButton>
        </Box>

        <DataGrid autoHeight={true} columns={columns} rows={rows} density={"compact"}
        getRowClassName={(params) => {
            return 'row-cls--'+params.row.row
        }
        }
        />
    </Box>
}

/*


            <TextField
                size="small"
                label="Date fin"
                value={dtEnd}
                onChange={(e) => {
                    setDtEnd(e.target.value);
                }}
            />
<TextField
                size="small"
                label="Date début"
                value={dtStart}
                onChange={(e) => {
                    setDtStart(e.target.value);
                }}
            />
 */