
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useEffect, useState} from "react";
import Login from "./features/Login/Login";
import axios from "axios";
import {setApiToken} from "./services/VeosApiService";
import Layout from "./components/Layout/Layout";
import ClientPage from "./features/Client/ClientPage";
import 'dayjs/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
    const [token, setToken] = useState();

    useEffect(() => {
        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === 401) {
                setToken(null);
            }
            return error;
        });
    }, [])

    useEffect(() => {
        setApiToken(token);
    }, [token])

    if(!token) {
        return <Login setToken={setToken} />
    }

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr"}>
          <BrowserRouter>
              <Layout setToken={setToken}>
                  <Routes>
                      <Route path="/client/:personId" element={<ClientPage/>}/>
                  </Routes>
              </Layout>
          </BrowserRouter>
      </LocalizationProvider>
  );
}

export default App;
