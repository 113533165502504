import CareneAppBar from "../CareneAppBar/CareneAppBar";
import {Stack} from "@mui/material";


export default function Layout({setToken, children}) {
    return <>
        <Stack sx={{ width: '100%' }}>
            <CareneAppBar logout={() => setToken('')}/>
            {children}
        </Stack>
    </>
}