import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { debounce } from '@mui/material/utils';
import {
    InputBase,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    useAutocomplete
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {AccountCircle} from "@mui/icons-material";
import {boby} from "../../services/VeosApiService";
import {useNavigate} from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function CarenePersonSearchField() {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const navigate = useNavigate();


    const fetch = React.useMemo(
        () =>
            debounce((request, callback) => {
                boby('WS_CARAPP_ACCOUNT_SEARCH', {
                    'SEARCH': request.input,
                    startRowNum: 0,
                    endRowNum: 10
                }).then((results) => {
                    callback(results.beans);
                });
            }, 400),
        [],
    );

    React.useEffect( () => {
        if(value) {
            console.log(value);
            if(value.ID_PER) {
                navigate('/client/'+value.ID_PER);
            }
        }
    }, [value, navigate])

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        id: 'use-autocomplete-search',
        getOptionLabel: (option) => typeof option === 'string' ? option : option.NOM_PER,
        filterOptions: (x) => x,
        options: options,
        autoComplete: true,
        includeInputInList: false,
        filterSelectedOptions: true,
        value: value,
        noOptionsText: 'Aucun résultat',
        onChange:(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
        },
        onInputChange:(event, newInputValue) => {
            setInputValue(newInputValue);
        }
    });
    return (
        <div>
            <Search {...getRootProps()}>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase placeholder="Rechercher..." inputProps={{...getInputProps()}}/>
            </Search>
            {groupedOptions.length > 0 ? (
                <Paper {...getListboxProps()} sx={{position:"absolute", zIndex: 1000}} elevation={3}>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {groupedOptions.map((option, index) => (
                            <ListItemButton key={index}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText {...getOptionProps({ option, index })} primary={option.NUM_PER+' - '+option.NOM_PER} />
                            </ListItemButton>
                        ))}
                    </List>
                </Paper>
            ) : null}
        </div>
    );
}
